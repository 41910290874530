@font-face {
  /* 사용할 폰트의 이름 */
  font-family: "Aware";
  font-display: fallback;

  /* 
  src: 사용할 폰트의 위치 및 형식 
     Safari, Android, iOS => ttf
     Modern Browsers => woff
     IE6-IE8 => eot
  */
  src: url("./AwareBold.ttf") format("truetype");

  /* 사용할 폰트의 기본 스타일 */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  /* 사용할 폰트의 이름 */
  font-family: "Wallpoet";
  font-display: fallback;

  src: url("./Wallpoet-Regular.ttf") format("truetype");

  /* 사용할 폰트의 기본 스타일 */
  font-weight: 400;
  font-style: normal;
}
